// React imports
import React, { useState, useEffect, useTransition} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Imports from 3rd party libraries
import CryptoJS from 'crypto-js';
import {XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, LineSeries, VerticalBarSeries, VerticalBarSeriesCanvas, MarkSeries, HeatmapSeries} from 'react-vis';
import Select from 'react-select';

// Common
import Configs from "../../Configs";
import Header from '../../common/header';
import PulseHeader from './pulseHeader';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import FilterWidget from '../../widgets/data/filterWidget.js';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Data widgets
import Heatmap from '../../widgets/heatmap';
import DataGrid, {ColumnOrColumnGroup} from 'react-data-grid';

// Icons
import {AiOutlineDownload, AiOutlineFilter} from 'react-icons/ai';
import {IoIosHelpCircleOutline} from 'react-icons/io';
import { FcHeatMap } from "react-icons/fc";
import { FaTableCells } from "react-icons/fa6";


// i18n
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';


// ---------------------------------------------------------------------------------------------------
function PulseReporting () {
  // Navigate
  const navigate = useNavigate();
  const {t} = useTranslation();

  // Employee Info
  const { token, companyID, employeeID, securityLevel } = useAuth();
  const { companyPrefereneces, pulseReportingData, isPulseReportingDataLoading } = useData();
  const {teamDirects, companyOrg, teamOrg} = useData();
  const {toasterMessage, setToasterMessage} = useData();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default number of rows per page

  if (Configs.devEnvironment) console.log('[pulseReporting.js][main()] pulseReportingData:', pulseReportingData);

  // Data
  const [data, setData] =  useState(null);
  const [filteredData, setFilteredData] = useState(null);

  // Available dimensions
  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableSubcategories, setAvailableSubcategories] = useState([]);
  const [availableManagers, setAvailableManagers] = useState([]);
  const [availableAreas, setAvailableAreas] = useState([]);
  const [availableSubareas, setAvailableSubareas] = useState([]);

  const [selectedRow, setSelectedRow] = useState('manager');
  const [selectedColumn, setSelectedColumn] = useState('category');

  // Control checkboxes
  const [showCategories, setShowCategories] = useState(true);
  const [showSubcategories, setShowSubcategories] = useState(false);

  // Filters
  const [filters, setFilters] = useState({
    area: '',
    subarea: '',
    manager: '',
    // employee: '',
    enabled: true
  });

  // DataGrid columns
  const dataGridColumnsAux = [
    {key: 'area', name: t('area'), renderHeaderCell({ filters, ...rest }) {
      return (
        <div className='flex flex-col bg-black'>
            <strong className='text-white'>{t('area')}</strong>
            <input
                className="field-input"
                value={filters?.area}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    area: e.target.value
                  })
                }
                // onKeyDown={inputStopPropagation}
              />
          </div>
          )
      },
    },
    {key: 'subarea', name: t('subarea')},
    {key: 'manager', name: t('manager')},
    {key: 'score', name: t('score')},
  ];

  const [dataGridColumns, setDataGridColumns] = useState(dataGridColumnsAux);

// ---------------------------------------------------------------------------------------------------
// After loading the view
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Check security level
        if (!(securityLevel.includes(Configs.securityLevelHR) || securityLevel.includes(Configs.securityLevelExec))) {
          navigate('/pulse/');
        }

        if (pulseReportingData && pulseReportingData?.length > 0) {
            // Build data table
            updateDataWihFiltersAndColumns();
        }

    }, [pulseReportingData, showCategories, showSubcategories, filters]);

// ---------------------------------------------------------------------------------------------------
// Data transform functions
// ---------------------------------------------------------------------------------------------------
function updateDataWihFiltersAndColumns() {
  // 1. Filter the data based on area, subarea, and manager filters
  const filteredData = pulseReportingData.filter(record => {
    const areaMatches = filters.area ? record.area === filters.area : true;
    const subareaMatches = filters.subarea ? record.subarea === filters.subarea : true;
    const managerMatches = filters.manager ? record.manager === filters.manager : true;
    return areaMatches && subareaMatches && managerMatches;
  });

  // 2. Aggregate the data based on user selection (categories or subcategories)
  const aggregatedData = aggregateDataByManagerAreaSubarea(filteredData);

  // 3. Define the columns based on user selection
  const dataGridColumns = updateColumnsWithInputs();

  // 4. Transform the aggregated data into rows for the table
  const gridData = transformAggregatedDataToRows(aggregatedData, dataGridColumns);

  // 5. Set the state with the new rows
  setData(gridData);
  setFilteredData(gridData);
}

// 2. Aggregate the data based on user selection (categories or subcategories)
function aggregateDataByManagerAreaSubarea(pulseReportingDataAux) {
  const aggregatedData = {};

  pulseReportingDataAux.forEach(record => {
    const area = record.area || 'Unknown Area';
    const subarea = record.subarea || 'Unknown Subarea';
    const manager = record.manager || 'Unknown Manager';

    // Create the unique row key for area, subarea, and manager
    const rowKey = `${area}_${subarea}_${manager}`;

    // Initialize the row if it doesn't exist
    if (!aggregatedData[rowKey]) {
      aggregatedData[rowKey] = {
        area,
        subarea,
        manager,
        data: {}
      };
    }

    // Determine the column key based on user's selection
    const columnKey = showSubcategories ? record.subcategory || 'Uncategorized Subcategory' : record.category || 'Uncategorized Category';

    // Initialize the column in the row if it doesn't exist
    if (!aggregatedData[rowKey].data[columnKey]) {
      aggregatedData[rowKey].data[columnKey] = {
        totalScore: 0,
        totalCount: 0
      };
    }

    // Add the answer score and count for the category/subcategory
    aggregatedData[rowKey].data[columnKey].totalScore += record.answerScore;
    aggregatedData[rowKey].data[columnKey].totalCount += record.answerCount;
  });

    
    // Update aggregated values
    // aggregatedData[rowKey][columnKey].totalScore += record.answerSum;
    // aggregatedData[rowKey][columnKey].count += record.answerCount;
    // aggregatedData[rowKey][columnKey].companyBenchmarkScore = record.companyBenchmarkScore;


    // Calculate average scores and vsBenchmark average
    // for (const rowKey in aggregatedData) {
    //     for (const columnKey in aggregatedData[rowKey]) {
    //         const entry = aggregatedData[rowKey][columnKey];
    //         entry.averageScore = entry.totalScore / entry.count;
    //         entry.companyBenchmarkScore = entry.companyBenchmarkScore;
    //     }
    // }

  // Now calculate the average score for each row and column
  Object.keys(aggregatedData).forEach(rowKey => {
    Object.keys(aggregatedData[rowKey].data).forEach(columnKey => {
      const totalScore = aggregatedData[rowKey].data[columnKey].totalScore;
      const totalCount = aggregatedData[rowKey].data[columnKey].totalCount;

      const averageScore = totalCount > 0 ? totalScore / totalCount : 0;
      aggregatedData[rowKey].data[columnKey].averageScore = averageScore.toFixed(1);  // Round to 1 decimal place
    });
  });

  return aggregatedData;
}

// 3. Define the columns based on user selection
function updateColumnsWithInputs () {
  // Populate maximum amount of fields
  const categoriesAux = pulseReportingData.map(record => record.category);
  const subcategoriesAux = pulseReportingData.map(record => record.subcategory);
  const managersAux = pulseReportingData.map(record => record.manager);
  const areasAux = pulseReportingData.map(record => record.area);
  const subareasAux = pulseReportingData.map(record => record.subarea);

  setAvailableCategories([...new Set(categoriesAux)]);
  setAvailableSubcategories([...new Set(subcategoriesAux)]);
  setAvailableManagers([...new Set(managersAux)]);
  setAvailableAreas([...new Set(areasAux)]);
  setAvailableSubareas([...new Set(subareasAux)]);

  // Update columns - Get default columns
  var columns = [...dataGridColumnsAux];

  // Add categories at the end of the columns array - if selected
  if (showCategories) {
    pulseReportingData?.forEach(record => {
      if (record.category) {
        const categoryColumn = columns.find(column => column.key === record.category);
        if (!categoryColumn) {
          columns.push({
            key: record.category,
            name: record.category,
            // formatter: ({ row }) => (
            //   <span>{row.key ? `${row.key.score} (${row.key.count})` : 'N/A'}</span>
            // )
          });
        }
      }
    });
  }

  // Subsequently, add subcategories as children of each category - if selected
  if (showSubcategories) {
    pulseReportingData?.forEach(record => {
      if (record.subcategory) {
        // Check if category already exists
        const categoryColumn = columns.find(column => column.key === record.category);
        if (categoryColumn) {
          // Check if subcategory already exists
          const subcategoryColumn = categoryColumn.children?.find(child => child.key === record.subcategory);
          if (!subcategoryColumn) {
            // Create children array if it doesn't exist
            if (!categoryColumn.children) {
              categoryColumn.children = [];
            }
            // Add subcategory
            categoryColumn.children.push({key: record.subcategory, name: record.subcategory});
          }
        }
      }
    });
  }

  if (Configs.devEnvironment) console.log('[pulseReporting.js][updateColumnsWithInputs()] columns:', columns);

  // Set state
  setDataGridColumns(columns);
  return columns;

}

// 4. Transform the aggregated data into rows for the table
function transformAggregatedDataToRows(aggregatedData, columns) {
  const rows = [];

  Object.keys(aggregatedData).forEach(rowKey => {
    const row = {
      area: aggregatedData[rowKey].area,
      subarea: aggregatedData[rowKey].subarea,
      manager: aggregatedData[rowKey].manager
    };

    // Fill in the category/subcategory data
    dataGridColumns.forEach(column => {
      if (column.key !== 'area' && column.key !== 'subarea' && column.key !== 'manager') {
        row[column.key] = aggregatedData[rowKey].data[column.key]?.averageScore || 'N/A';
      }
    });

    // Add the row to the rows array
    rows.push(row);

    // Log
  });

  if (Configs.devEnvironment) console.log('[pulseReporting.js][transformAggregatedDataToRows()] row:', rows);

  return rows;
}

// ---------------------------------------------------------------------------------------------------
// DataGrid functions
// ---------------------------------------------------------------------------------------------------
// rowKeyGetter is necessary for row selection
function rowKeyGetter(row) {
  return row.date;
}

function clearFilters () {
  setFilters({
    area: '',
    subarea: '',
    manager: '',
    // employee: '',
    enabled: true
  });
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
    
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />
      <Bot />

      <div className="main">

        <PulseHeader />
            
        <div className='flex flex-row items-start'>
        <div className="left-big-view">

            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

            {/* Categories per manager */}
            <div className="flex flex-col text-left items-start py-2">
              <div className='flex flex-row items-center justify-between'>
                <Tooltip content={t('pulse.reporting.drivers.description')}>
                    <h1 className='section-title'>
                        {t('pulse.reporting.drivers.title')} <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span>
                    </h1>
                </Tooltip>
                </div>

                {isPulseReportingDataLoading ?
                    <Loader />
                :
                  <div className='flex flex-col w-full'>
                        {/* Data view options*/}
                        <div className='flex flex-row items-center justify-between'>
                          <div className='flex flex-row items-center space-x-2'>
                            <div className='flex flex-row items-center space-x-2 text-gray-600 rounded-lg hover:bg-gray-200 hover:cursor-pointer p-2 '>
                              <FcHeatMap className='text-xl'/>
                              <span className='text-xs font-medium'>{t('heatmap')}</span> 
                            </div>

                            <div className='flex flex-row items-center space-x-2 text-gray-600 rounded-lg hover:bg-gray-200 hover:cursor-pointer p-2'>
                              <FaTableCells className='text-xl'/>
                              <span className='text-xs font-medium'>{t('table')}</span> 
                            </div>

                          </div>
                        </div>

                        {/* Checkboxes */}
                        <div className='flex flex-row items-center space-x-2 mt-2'>
                          <div className='flex flex-row items-center space-x-2 text-xs text-gray-600 rounded-lg hover:bg-gray-200 hover:cursor-pointer p-2'>
                            <input type="checkbox" id="showCategories" name="showCategories" checked={showCategories} onChange={(e) => setShowCategories(e.target.checked)} />
                            <label htmlFor="showCategories">{t('pulse.reporting.drivers.showCategories')}</label>
                          </div>

                          <div className='flex flex-row items-center space-x-2 text-xs text-gray-600 rounded-lg hover:bg-gray-200 hover:cursor-pointer p-2'>
                            <input type="checkbox" id="showSubcategories" name="showSubcategories" checked={showSubcategories} onChange={(e) => setShowSubcategories(e.target.checked)} />
                            <label htmlFor="showSubcategories">{t('pulse.reporting.drivers.showSubcategories')}</label>
                          </div>

                        </div>  

                        <div className='flex flex-col w-full justify-between'>

                          {/* DataGrid */}
                          <div className='flex flex-col w-full bg-white shadow-xl rounded-lg p-4 mt-4'>
                            {(data?.length > 0) ?
                              <DataGrid 
                                columns={dataGridColumns}
                                rowKeyGetter={rowKeyGetter}
                                rows={filteredData}
                                enableCellSelect={true}
                                // topSummaryRows={summaryRows}
                                // bottomSummaryRows={summaryRows}
                                defaultColumnOptions={{
                                  // sortable: true,
                                  resizable: true
                                }}
                              />

                              :
                              <div className='flex flex-col w-full items-center justify-center'>
                                <p className='text-gray-600 text-sm'>{t('pulse.reporting.drivers.empty')}</p>
                              </div>
                            
                            }
                          </div>

                        {/* Heatmap */}
                        {/* <div className='flex flex-col w-full bg-white shadow-xl rounded-lg p-4 mt-4'>
                          {(data?.length > 0) ?
                            <Heatmap data={filteredData} rowDimension={"manager"} columnDimension={"category"} />

                            :
                            <div className='flex flex-col w-full items-center justify-center'>
                              <p className='text-gray-600 text-sm'>{t('pulse.reporting.drivers.empty')}</p>
                            </div>
                          
                          }
                        </div> */}
                      </div>
                    </div>
                }
            </div>



            {/* Questions */}
            {/* <div className="flex flex-col text-left ">
                <Tooltip content={t('pulse.reporting.questions.tooltip')}>
                    <h1 className='section-title'>
                        {t('pulse.reporting.questions.title')}
                    </h1>
                </Tooltip>

                {isPulseReportingDataLoading ?
                    <Loader />
                :
                    <div className='flex flex-row items-start justify-between'>
                            <PulseFilterWidget 
                            onFilterChange={() => setCurrentPage(1)}

                            />
                    </div>
                }
            
            </div> */}
        </div>
         <div className='right-small-view'>

        </div>

      </div>
      </div> 
    </React.Fragment>
    );
}

export default PulseReporting;